<template>
	<div class="row h-100">
      <div class="col-md-12 hero" :style="{height: Short ? '500px' : height + 'px', backgroundImage: (Still ? 'url(' + (mobile? MobileStill : Still) + ')' : '')}">
        <video muted loop autoplay playsinline>
          <source :src="isMobile() && MobileVideo ? MobileVideo : Video" type="video/mp4" />
        </video>
        <div class="left" :style="{marginTop: (height/2) - 60 + 'px'}">
          <h1 v-html="Heading"></h1>
          <h2 v-html="SubHeading"></h2>
        </div>
        <img v-if="!Short" class="down" src="@/assets/down-w.svg" />
      </div>
    </div>
</template>

<script>
export default {
  props: ['Heading', 'SubHeading', 'Short', 'Video', 'MobileVideo', 'Still', 'MobileStill'],
  data() {
    return {
      height : 0,
      mobile: false
    }
  },
  methods: {
    scale : function(){
      this.height = window.innerHeight;
      this.mobile = window.innerWidth < 767
    },
    isMobile: function() {
      return (window.innerWidth < 700);
    }
  },
  created: function(){ 
    this.scale();
    window.addEventListener("resize", this.scale);
  },
  destroyed() {
    window.removeEventListener("resize", this.scale);
  }
}
</script>

<style scoped>
.hero {
  b--ackground-image: url("~@/assets/HeroBG.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: gainsboro;
  color: #281c3b;
  text-align: left;
}
.left {
  margin-top: 50%;
  margin-bottom: auto;
}
.hero video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  z-index: 0;
}
.hero h1 {
  margin-bottom: 15px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  margin-left: 130px;
}
.hero h2 {
  line-height: 30px;
  max-width: 400px;
  position: relative;
  z-index: 1;
  margin-left: 130px;
  font-size: 20px;
}
.hero img {
  height: 100px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  margin-left: 130px;
  display: none;
}
.hero .down {
  position: absolute;
  bottom: 0px;
  display: block;
  height: 20px;
  left: 50%;
  margin-left: -10px;
}
@media all and (max-width: 767px)
{
  .hero {
    background-image: url("~@/assets/HeroBG_Mobile.png");
  }
  .hero, .hero h1, .hero h2{
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    max-width: unset;
  }
  .hero h1 {
    padding-top: 40%;
  }
}
</style>