<template>
	<div id="about">
		<div class="content">
			<title-hero Heading="" SubHeading="" Short="true"
				:Video="require('@/assets/PeeledOrange.mp4')"
				:MobileVideo="require('@/assets/PeeledOrange_Mobile.mp4')"
			/>
			<div class="about-content">
				<h1>Money Guided</h1>
				<h2>Opening App</h2>
<p>If you have our app installed it should open now.  If not, why not get in touch to find out more?</p>
			</div>
		</div>
	</div>
</template>

<script>

import TitleHero from "./TitleHero.vue";
export default {
	components: { TitleHero },
	methods: {
		launch: function(){
			window.location='moneyguided://syso.com';
		},
	},
	mounted: function() { this.launch(); }
}


</script>

<style scoped>
	h1 {
		margin-top: 50px;
		text-align: left;
		margin-bottom: 30px;
		font-size: 25px;
		font-weight: bold;
	}
	h2 {
		font-size: 20px;
		margin-bottom: 30px;
		text-align: left;
	}
	p {
		text-align: left;
	}
	.about-content {
		width: 47%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 50px;
	}
</style>