<template>
	<div>
		
		<b-modal centered size="lg" id="contact-modal" content-class="shadow">
			<template #modal-header>
				<span/>
			</template>
			<div class="row contact">
				<div class="col-md-4 contact-left">
					<img src="@/assets/MGLogo-w.png"/>
					<h1>Register interest</h1>
					<p>To find out more about how Money Guided can help your employees, register your interest and our team will be in touch.</p>
				</div>
				<div class="col-md-8 contact-right">
					<b-form>
						<b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
							<b-form-input id="input-2" placeholder="Enter name" required v-model="enquiry.name"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Email address:" label-for="input-1" description="We'll never share your email with anyone else.">
							<b-form-input id="input-1" type="email" placeholder="Enter email" required v-model="enquiry.email"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-3" label="Company:" label-for="input-3">
							<b-form-input id="input-3" placeholder="Company name" required v-model="enquiry.company"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-4" label="Job title:" label-for="input-4">
							<b-form-input id="input-4" placeholder="Job title" v-model="enquiry.jobTitle"></b-form-input>
						</b-form-group>
						<br/>
						<span>{{message}}</span><b-button class="float-right" pill @click="sendEnquiry">Submit</b-button> <b-button class="float-right" pill @click="cancel">Cancel</b-button> 
					</b-form>
				</div>
			</div>
			<template #modal-footer>
				<span/>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
	data() {
		return {
			message: "",
			enquiry: {
				"name" : "",
				"email" : "",
				"subject": "",
				"message": "",
				"company": "",
				"jobTitle": ""
			}
		}
	},
	methods: {
		cancel : function() {
			this.$bvModal.hide("contact-modal");
		},
		sendEnquiry : function() {
			this.message = "Sending . . .";
			if (this.enquiry.email == "") { this.message = "Please provide an email address"; return; }
			this.enquiry.message = "Name: " + this.enquiry.name + ".  Email: " + this.enquiry.email + ".  Company: " + this.enquiry.company + ". Job title:" + this.enquiry.jobTitle + ".";
			this.$http.post("https://api.sysodev.link/Enquiries", 
			this.enquiry)
			.then(
				() => {
					this.enquiry = {
						"name" : "",
						"email" : "",
						"subject": "",
						"message": "",
						"company": "",
						"jobTitle": ""
					};
					this.message = "Thank you, your enquiry has been sent";
					this.$bvModal.hide("contact-modal");
				}
			);
		}
	}
}
</script>

<style>
	.modal-header, .modal-footer{
		display:none !important;
	}
	.modal-body {
		padding:0px !important;
	}
	.modal-content {
		border-radius: 1rem !important;
	}
	.contact {
		color: #ffffff;
	}
	.contact img {
		height: 30px;
	}
	.contact h1 {
		font-size: 27px;
		font-weight: bold;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.contact p {
		font-size: 10px;
	}
	.contact-left {
		background-image: url("~@/assets/ContactBG.png");
		background-position: right;
		background-size: 140%;
		padding: 50px;
		padding-left: 30px !important;;
		border-radius: 1rem 0px 0px 1rem;
	}
	.contact-right {
		padding: 50px !important;
		color: #2c3e50;
		font-size: 11px;
	}
	.contact-right input {
		font-size: 14px;
	}
	.contact-right button {
		background-color: #2c3e50;
		font-size: 12px;
		width: 90px;
	}
	.contact .btn {
		margin-left: 10px;
	}
	@media all and (max-width: 767px)
	{
		.contact-left {
			display:none;
		}
	}
</style>