<template>
	<div id="linked">
		<div class="content">
			<showcase
			Variant="light"
			Heading="Great!"
			Body="We're now sending you back to the app, if you are not sent there in the next 30 seconds, please re-open the Money Guided App."
			:Image="require('@/assets/MGLogo.png')"
			/>
		</div>
		<span id="extra" class="small">{{message}}</span>
	</div>
</template>

<script>

	import Showcase from "./Showcase.vue";
	export default {
		components: { Showcase },
		data() {
			return {
				message: "Loading . . ."
			}
		},
		methods: {
			getParameterByName: function(name, url = window.location.href) {
				name = name.replace(/[[\]]/g, '\\$&');
				var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
					results = regex.exec(url);
				if (!results) return null;
				if (!results[2]) return '';
				return decodeURIComponent(results[2].replace(/\+/g, ' '));
			}
		},
		created: function() {
			try 
			{
				this.message = "Launching . . .";
				window.location='moneyguided://syso.com/?screen=LinkBankAccountPage';
			}
			catch(err)
			{
				this.message = err;
			}
			var extra = this.getParameterByName('extra');
			if (extra && extra != "") {
				this.message = extra;
			}
		}
	}

</script>

<style scoped>
	h1 {
		margin-top: 50px;
		text-align: left;
		margin-bottom: 30px;
		font-size: 25px;
		font-weight: bold;
	}
	h2 {
		font-size: 20px;
		margin-bottom: 30px;
		text-align: left;
	}
	p {
		text-align: left;
	}
	.about-content {
		width: 47%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 50px;
	}
	.small {
		font-size: small;
	}
</style>