<template>
	<div>
		<div id="download">
		
			<div class="row">
				<h1>Download Money Guided</h1>
				<p>Our intention is to help people develop a more positive relationship with their money. 
					Developed by our team of finance experts and clinical psychologists Money Guided provides the insights and tools to help you 
					make the right decisions at the right time.</p>
			</div>


			<b-row class="mt-4 centered">
				
				<b-col>
					<div class="dl">
						<h2>Android</h2>
						<p class="small centered">Mobile and Tablet</p>
						<a href="https://play.google.com/store/apps/details?id=com.syso.moneyguided"><img :src="require('../assets/android.png')" /></a>
						<!-- <a href="https://s3.eu-west-2.amazonaws.com/content.sysodev.link/app-release.apk"><img src="https://s3.eu-west-2.amazonaws.com/content.sysodev.link/android-dd.png" /></a> -->
					</div>
				</b-col>
				
				<b-col>
					<div class="dl">
						<h2>iOS</h2>
						<p class="small centered">Mobile</p>
						<a href="https://apps.apple.com/gb/app/money-guided/id6443701567"><img :src="require('../assets/ios.svg')" /></a>
					</div>
				</b-col>

			</b-row>

		</div>

		<feature-carousel Heading="" />

	</div>
	
</template>

<script>
import FeatureCarousel from "./FeatureCarousel.vue";
export default {
	components: { FeatureCarousel },
	name: "Download",
}
</script>

<style scoped>

	#download {
		padding-top: 130px;
		p--adding-bottom: 130px;
		width: 60%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	.dl
	{
		background-color: #281c3bea;
		border-radius: 3%;
		padding: 50px;
		padding-top: 30px;
		margin: 10px;
		max-width: 400px;
		color: #ffffff;
	}

	.dl img{
		margin-top: 30px;
		width: 100%;
		max-height: 80px;
	}

	.centered {
		text-align: center !important;
	}

	h1 {
		font-size: 27px;
		font-weight: bold;
		margin-bottom: 10px;
		text-align: left;
	}
	h2 {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5px;
		margin-top: 30px;
	}
	.row {
		text-align: left;
	}

	p {
		text-align: left;
	}

	@media all and (max-width: 767px)
	{
		#download,p,h1 {
			text-align: center;
		}
	}

</style>

<style>
	.feature-carousel {
		p--adding-top: 60px;
		padding-bottom: 60px;
		background-color: #ffffff !important;
	}
</style>