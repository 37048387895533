<template>
	<div class="row">
    <div class="col-md-12 hero subhero" :style="{'min-height' : height + 'px', backgroundImage: (Still ? 'url(' + (mobile? MobileStill : Still) + ')' : '')}">
      <video muted loop autoplay playsinline>
        <source :src="isMobile() && MobileVideo ? MobileVideo : Video" type="video/mp4" />
      </video>
      <div class="left" :style="{marginTop: (height/2) - 100 + 'px'}">
        <h1 v-html="Heading"></h1>
        <p v-html="Body"></p>
      </div>
      <img v-if="First" class="down" src="@/assets/down.svg" />
    </div>
  </div>
</template>

<script>

export default{
	props: ['Heading', 'Body', 'Video', 'MobileVideo', 'First', 'Still'],
  data() {
    return {
      height : 0
    }
  },
  methods: {
    isMobile: function() {
      return (window.innerWidth < 700);
    },
    scale : function(){
      this.height = window.innerHeight - (this.First ? 0 : 60);
    },
  },
  created: function(){ 
    this.scale();
    window.addEventListener("resize", this.scale);
  },
  destroyed() {
    window.removeEventListener("resize", this.scale);
  }
}
</script>

<style scoped>
.hero {
  h--eight: 100%;
  m--in-height: 890px !important;
  background-image: url("~@/assets/HeroBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: gainsboro;
  p--adding-top: 270px;
  color: #281c3b;
  text-align: left;
}
.hero video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  z-index: 0;
}
.hero h1 {
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  margin-left: 130px;
}
.hero h2 {
  line-height: 30px;
  max-width: 400px;
  position: relative;
  z-index: 1;
  margin-left: 130px;
}
.hero img {
  height: 100px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  margin-left: 130px;
  display: none;
}
.subhero h1 {
  font-size: 28px;
  margin-bottom: 2px;
  font-weight: 600;
}
.subhero h3 {
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}
.subhero p {
  text-align: left !important;
  font-size: 12px;
  margin-top: 10px;
  z-index: 1;
  margin-left: 130px;
  position: relative;
  max-width: 400px;
  line-height: 2;
}
h2 {
  font-size: 20px;
}
.down {
  position: absolute;
  bottom: 0px;
  display: block;
  height: 20px;
  left: 50%;
  margin-left: -10px;
}
@media all and (max-width: 767px)
{
  .hero {
    padding-top: 40%;
    background-image: url("~@/assets/HeroBG_Mobile.png");
  }
  .hero, .hero h1, .hero h2 .subhero h1, .subhero h2, .subhero p{
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    max-width: unset;
  }
  .subhero p{
    text-align: center !important;
    margin-left: 40px;
    margin-right: 40px;
    max-width: unset;
  }
  .subhero {
    padding-bottom: 60px;
  }
}
</style>