<template>
	<div>
		
		<b-modal centered size="lg" id="benefit-modal" content-class="shadow">
			<template #modal-header>
				<span/>
			</template>
			<div class="row contact">
				<div class="col-md-4 contact-left">
					<img src="@/assets/MGLogo-w.png"/>
					<h1>MG Benefit Calculator</h1>
					<p>To find out how much your business could benefit by using Money Guided, use the form opposite.</p>
				</div>
				<div class="col-md-8 contact-right" v-if="areas && !result">
					<b-form>
						<b-form-group id="input-group-2" label="How many people do you employ?:" label-for="input-2">
							<b-form-input id="input-2" placeholder="" required v-model="request.employees"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="What is their median salary?:" label-for="input-1">
							<b-form-input id="input-1" placeholder="" required v-model="request.medianPay"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-3" label="What is your main location:" label-for="input-3">
							<b-form-select id="input-3" :options="areas" placeholder="" required v-model="request.area"></b-form-select>
						</b-form-group>
						
						<br/>
						<b-button class="float-right" pill @click="getFigures">Calculate</b-button>
					</b-form>
				</div>
				<div v-if="result" class="col-md-8 contact-right">
					<strong><b-row><b-col cols="9">An average engaged user could benefit by: </b-col><b-col>&pound;{{ Intl.NumberFormat('en-GB').format(result.perEmployeeSavings) }}*</b-col></b-row>
					<b-row><b-col cols="9">That's like getting a pay rise of: </b-col><b-col>&pound;{{ Intl.NumberFormat('en-GB').format(result.perEmployeePayRise) }}*</b-col></b-row></strong>
					<b-row><b-col cols="9">Saving you as a business: </b-col><b-col>&pound;{{ Intl.NumberFormat('en-GB').format(result.companySavingsOnPayRise) }}*</b-col></b-row>
					<b-row><b-col cols="9">At an estimated cost of: </b-col><b-col>&pound;{{ Intl.NumberFormat('en-GB').format(result.costToBusiness) }}*</b-col></b-row>
					<small><p style="margin-top: 50px">* Based on average discounts available soon through the Money Guided app, applied to average spending information from the ONS and Money Guided's assumptions for coverage levels.</p>
						<p>Assumes an engaged user uses tools to adjust 2% of their spending habits and ensures that their home and car insurance are renewed at competitive rates with commission payback to the user.</p></small>
					<br/>
					<b-button class="float-right" pill @click="result=null">Reset</b-button>
				</div>
			</div>
			<template #modal-footer>
				<span/>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: "PayRiseCalculator",
	data() {
		return{
			request:{employees:10, area:"London", medianPay: "55000"},
			areas:null,
			result:null
		}
	},
	methods: {
		getAreas: function() {
			this.$http.get("https://api.sysodev.link/EffectivePayRiseCalculations/Areas")
			.then((result) => {
				this.areas = result.data;
			});
		},
		getFigures: function() {
			this.$http.post("https://api.sysodev.link//EffectivePayRiseCalculations", this.request)
			.then((result) => {
				this.result = result.data;
			});
		},
		onLoad() {
			this.getAreas();
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style>
	.modal-header, .modal-footer{
		display:none !important;
	}
	.modal-body {
		padding:0px !important;
	}
	.modal-content {
		border-radius: 1rem !important;
	}
	.contact {
		color: #ffffff;
	}
	.contact img {
		height: 30px;
	}
	.contact h1 {
		font-size: 27px;
		font-weight: bold;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.contact p {
		font-size: 10px;
	}
	.contact-left {
		background-image: url("~@/assets/ContactBG.png");
		background-position: right;
		background-size: 140%;
		padding: 50px;
		padding-left: 30px !important;;
		border-radius: 1rem 0px 0px 1rem;
	}
	.contact-right {
		padding: 50px !important;
		color: #2c3e50;
		font-size: 11px;
	}
	.contact-right input {
		font-size: 14px;
	}
	.contact-right button {
		background-color: #2c3e50;
		font-size: 12px;
		width: 90px;
	}
	.contact .btn {
		margin-left: 10px;
	}
	@media all and (max-width: 767px)
	{
		.contact-left {
			display:none;
		}
	}
</style>