<template>
<div class="qanda">
	<question class="row" v-b-toggle.q1 variant="primary"
		:class="visible ? null : 'collapsed'"
		:aria-expanded="visible ? 'true' : 'false'"
		aria-controls="collapse-4"
		@click="visible = !visible"
	>
		<div class="col-md-11" v-html="Question"></div>
		<div class="col-md-1"><img  src="@/assets/plus.png" /></div>
	</question>
	<b-collapse v-model="visible" class="row answer">
		<p v-html="Answer"></p>
	</b-collapse>
	<div class="seperator"></div>
</div>
</template>

<script>
export default{
	props: ['Question', 'Answer'],
	data() {
      return {
        visible: false
      }
    }
}
</script>

<style scoped>
	.row {
		text-align: left;
	}
	.col-md-1 {
		text-align: right;
	}
	.col-md-11 {
		p--adding-left:0px;
	}
	question {
		padding-top: 15px;
		padding-bottom: 15px;
		font-weight: 400;
	}
	question img{
		height: 18px;
		margin-top: 2px;
	}
	.answer {
		padding: 20px;
		padding-top: 0px;
	}
	.seperator{
		border-bottom: 1px solid gainsboro !important;
	}
</style>
