<template>
  <div class="content">

    <video-showcase
      Heading="People are the<br/>
        beating heart of any<br/>
        great organisation"
      Body="Supporting your teams and looking out for
        their best interests will help them perform at
        their best."
      :Video="require('@/assets/Polos.mp4')"
      :First="true"
      :MobileVideo="require('@/assets/Polos_Mobile.mp4')"
      :Still="require('@/assets/Polos.png')"
      :MobileStill="require('@/assets/Polos.png')"
    />

    <showcase
      Variant="light"
      Heading="We know managing<br/>
        finances can<br/>
        be challenging"
      Body="This can have a very real impact on mental
        health & business performance. 80% of UK
        employees admit financial worries impact
        them at work."
      Sources="* <a href='https://happiful.com/80-of-workers-say-money-worries-affect-their-performance/' target=_blank class=plain-link>FT.com, July 2022; YouGov, April 2022</a>"
      :Image="require('@/assets/E2.png')"
    />

    <showcase
      Variant="dark"
      Heading="For many, the current<br/>
        cost of living crisis is<br/>
        unprecedented"
      Body="76% of UK adults are worried about the rising price of life."
      :Image="require('@/assets/E3.png')"
    />

    <showcase
      Variant="light"
      Heading="We know prioritising<br/>
        employee wellbeing<br/>
        is crucial for success"
      Body="People who have high levels of financial wellbeing
        share a number of characteristics.<br/><br/>
        •  Better control of their day-to-day and month-to-
        month finances.<br/>
        • Can absorb a financial shock.<br/>
        • Can confidently and consistently set and reach
        financial goals.<br/>
        • Believe they have the financial flexibility to do what
        they value and what makes them happy."
      :Image="require('@/assets/E4.png')"
    />

    <showcase
      Variant="dark"
      Heading="Building resilience,<br/>
        encouraging growth"
      :Image="require('@/assets/E5.png')"
      Link="article/08d9fd24-eaaa-491a-852f-d522815cb226"
      ImageLink="article/08d9fd24-eaaa-491a-852f-d522815cb226"
    >
    </showcase>

    <showcase
      Variant="light"
      Heading="Bringing balance<br/>
        to the workplace"
      Body="Money Guided helps your employees better<br/>
        understand and take control of their finances. Our<br/>
        intention is to encourage and form more positive<br/>
        relationships with money"
      :Image="require('@/assets/E6.png')"
      Contact="true"
    />

  </div>

</template>

<script>
import Showcase from "./Showcase.vue";
import VideoShowcase from "./VideoShowcase.vue";

export default {
  components: { Showcase,VideoShowcase },
  name: "Home",
};
</script>

<style scoped>

</style>
