<template>
  <div id="app">
    <Menu />
    <div class="content">
      <router-view></router-view>
    </div>
    <Contact/>
    <MGBenefitCalculator/>
    <Footer />
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import Contact from './components/Contact.vue'
import MGBenefitCalculator from './components/MGBenefitCalculator.vue';

export default {
  name: 'App',
  components: {
    Menu,
    Footer,
    Contact,
    MGBenefitCalculator
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

html, body {
  font-family: 'Montserrat', sans-serif !important;
  overflow-x: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #281C3B;
}

.btn, .btn-mg {
    color: #ffffff !important;
		background-color:#281C3B !important;
		font-size: 11px !important;
		width: 100px !important;
		height: 35px !important;
}

.btn-mg-arrow {
    width: 130px !important;
    padding-right: 40px !important;
    background-image: url('~@/assets/right-arrow.png');
    background-repeat: no-repeat;
    background-position-x: 97px;
    background-position-y: 12px;
    background-size: 15px;
}

.plain-link {
  color: #271c3a !important;
}

</style>
