<template>
	<div id="articles">
		<div id="grid" class="row gx-4 text-center" v-if="articles">
			<div class="col-sm-6 col-lg-4" v-for="a in articles" :key="a.id">
				<article-thumb 
					:Link="'#/article/' + a.id" 
					:File="a.fileName" 
					:Image="a.image" 
					:Title="a.title" 
					:ShortSummary="a.shortSummary" 
					:Tag="a.tagsArray[0]" 
					:ID="a.id"
				/>
			</div>
		</div> 
	</div>
</template>

<script>
import ArticleThumb from './ArticleThumb.vue';
export default {
  components: { ArticleThumb },
	data() {
		return {
			articles: null,
			baseUrl: "https://s3.eu-west-2.amazonaws.com/content.sysodev.link/"
		}
	},
	methods: {
		getArticles: function () {
			this.$http.get("https://api.sysodev.link/CMS/Articles?forApp=false&forWeb=true")
				.then(
					response => {
						this.articles = response.data;
					}
				);
		}
	},
	created: function() {
		this.getArticles();
	}
}
</script>

<style scoped>
	#articles {
		padding: 130px;
		padding-top: 80px;
	}
	@media all and (max-width: 767px)
	{
		#articles {
			padding: 30px;
			padding-top: 40px;
		}
	}
</style>