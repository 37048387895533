<template>
	<div id="about">
		<div class="content">
			<title-hero Heading="" SubHeading="" Short="true"
				:Video="require('@/assets/PeeledOrange.mp4')"
				:MobileVideo="require('@/assets/PeeledOrange_Mobile.mp4')"
			/>
			<div class="about-content">
				<h1>About</h1>
				<h2>Feel Good Financial Outcomes</h2>
<p>We believe that everyone deserves access to high-quality, personalised financial 
guidance.<br/><br/>
 
Our intention is to help people develop a more positive relationship with their money. 
Developed by our team of finance experts and clinical psychologists Money Guided 
provides the insights and tools to help you make the right decisions at the right time. <br/><br/>
 
That's why we've created a platform that provides people with the insights and tools to 
help them make the right plans and financial decisions. <br/><br/>
 
Helping people feel more in control of their finances, have a better relationship with 
their money and enjoy a better life today and tomorrow.</p>
			</div>
		</div>
	</div>
</template>

<script>
import TitleHero from "./TitleHero.vue";
export default {
	components: { TitleHero }
}
</script>

<style scoped>
	h1 {
		margin-top: 50px;
		text-align: left;
		margin-bottom: 30px;
		font-size: 25px;
		font-weight: bold;
	}
	h2 {
		font-size: 20px;
		margin-bottom: 30px;
		text-align: left;
	}
	p {
		text-align: left;
	}
	.about-content {
		width: 47%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 50px;
	}
</style>