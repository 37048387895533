<template>
	<div id="faq">
		
		<div class="row">
			<h1>FAQ's</h1>
		</div>

<div class="row">
	<h2>About Money Guided</h2>
</div>

<question-and-answer Question="What is Money Guided?"
Answer="Money Guided is a financial management app that allows you to connect and add your existing bank accounts, credit cards, savings, mortgages and insurance products in one easy-to-manage place. 
It then combines this with powerful spending analysis & planning tools, expert guides tailored to your needs and a unique commission passback model to help save you time, make money and achieve a greater sense of financial control and wellbeing."/>

<question-and-answer Question="How does Money Guided work?"
Answer="Your Money Guided journey starts when you securely link your account(s) through our Open Banking connection. Our sophisticated Moves engine then monitors your situation and provides you with timely, insight-driven guidance, helping you make better informed financial decisions. 
As you add more information about your other financial products, you start to see a fuller picture of your situation in one place, increasing the control you have when it comes to managing your money. "/> 

<question-and-answer Question="Is Money Guided a bank?"
Answer="No, Money Guided is not a bank. Rather, it is a service that connects your existing bank accounts, financial commitments and products, enabling you to view everything in one place."/>

<question-and-answer Question="Will you give investment advice?"
Answer="No, we will not provide investment advice. We will provide educational articles and helpful tips if you want to learn more about investments. "/>

<question-and-answer Question="How much will Money Guided cost?"
Answer="Employer - The cost of Money Guided licences vary depending on company size and number of employees. Employers pay for this as part of their employee benefit package, which then gives their employees unlimited access. To find out more, register your interest and we'll get in touch.<br/>
Member - The full cost of your Money Guided subscription is paid for by your employer as part of your employer's benefits package. Therefore, there is no cost to individual members for general use of the app."/>

<question-and-answer Question="Is my money secure?"
Answer="Yes. We use biometric security and bank-level 256-bit encryption. Accounts will be securely connected through Open Banking APIs, giving you complete control at all times. Additionally, Money Guided does not hold any of your money on your behalf."/>


<div class="row">
	<h2>Getting Started</h2>
</div>

<question-and-answer Question="How do I sign up?"
Answer="You should have received an email from your employer with information on Money Guided and an invitation to download the app. If you have not received this email, please contact your HR department."/>

<question-and-answer Question="What information will I need to have to hand?"
Answer="When you first install the app you will need to enter your work email address to confirm that you work for an employer who has purchased a licence for you. Later in the sign up process you will be able to switch to a personal email address, something we strongly recommend.
To link accounts using Open Banking you will need the relevant mobile banking app from the current account or credit card provider installed on the same phone as the Money Guided app."/>

<div class="row">
	<h2>Me and My Employer</h2>
</div>

<question-and-answer Question="Do you share my personal and financial information with my employers?"
Answer="No, individual personal information is never shared with employers. Your financial situation is your own."/>

<div class="row">
	<h2>My Account</h2>
</div>

<question-and-answer Question="How can I update my personal details?"
Answer="From the Home page on the app, click on the 3 dots in the top right-hand corner. This takes you to the Settings page. From here you can select ‘Personal Information' and then edit your personal information."/>

<question-and-answer Question="I want to close my account"
Answer="If you would like to close your account please email help@moneyguided.com and let us know that you would like to leave. You can also create this email by selecting ‘Help' from within the Settings menu."/>

<question-and-answer Question="I forgot/want to change my password"
Answer="If you haven't set up biometric access to your account and/or you need to change your pass code, just follow the ‘Forgotten your passcode?' prompt at login. If you also need to change your password, click ‘I Forgot' and enter the email address you used to register your account."/>

<question-and-answer Question="I entered an incorrect email address during sign up. What should I do?"
Answer="Please email help@moneyguided.com and include details of the email address you used to set up your account. We can then delete the incorrect account so that you can start again.
How do I get in contact with someone at Money Guided to discuss a specific problem?
We're here to help with all of your questions. You can reach us via help@moneyguided.com, or select ‘Help' from the Settings menu within the app. We'll do our best to get back to you within 6 working hours."/>

<question-and-answer Question="My phone has been lost or stolen. What should I do?"
Answer="Please email help@moneyguided.com and we can ensure that your account is secure. When you have replaced your phone you can download the app again and email us to open your account back up to you."/>

<question-and-answer Question="How do I report unauthorised access to my account?"
Answer="Please get in touch straight away by emailing help@moneyguided.com or by selecting ‘Help' from the Settings menu. We will suspend your account and send you a password reset."/>


<div class="row">
	<h2>Access to Money Guided</h2>
</div>

<question-and-answer Question="Will I be able to use Money Guided if I live outside the UK?"
Answer="Currently we are only able to accept members who are UK residents."/>

<question-and-answer Question="Do I need to be a UK Citizen to use Money Guided?"
Answer="No, you do not need to be a UK citizen to use the Money Guided app. You just need to be resident in the UK and at least 18 years of age."/>

<question-and-answer Question="Can I use Money Guided in a web browser?"
Answer="Money Guided is not currently available for use in a web browser."/>

<div class="row">
	<h2>Partners</h2>
</div>

<question-and-answer Question="Which partners do you work with?"
Answer="Currently, we work with the following providers:<br/>
So-Sure - Home contents insurance and phone insurance<br/>
Altura - Mortgages and personal insurances<br/>
City Financial - Mortgages and personal insurances<br/>
We can also make referrals to the StepChange Debt Charity."/>

<question-and-answer Question="How have you chosen your partners?"
Answer="We consider the needs of our customers and aim to identify partners who can deliver a competitive solution for these needs. One criteria that is essential is that the partner is happy for us to pass our commission back to our members in the form of the Money Guided Savings Boost.
While we have these agreements in place, we always encourage our members to shop around for the best deal and seek advice from a suitably qualified professional where necessary."/>

<question-and-answer Question="Do you share my data with these third parties?"
Answer="In some situations we will share your data with a third party when you follow a link to their website. If this does happen, we will only share information that is relevant to the product you are enquiring about in order to simplify your experience. You will be notified about this prior to the referral being made. You can read more about our privacy policy here."/>

<question-and-answer Question="What happens if I have a problem with a policy I've bought via one of your partners?" 
Answer="If you experience a problem with a policy provided by one of our partners we would encourage you to raise the issue with them as soon as possible so they can investigate.
However, while the agreement is between you and the partner firm, we would still like to know about any issues you may have so we can ensure that we are delivering the best proposition possible to our members. Please email help@moneyguided.com, or select ‘Help' from the Settings menu and let us know if you're experiencing an issue. "/>

<question-and-answer Question="Do you get paid by these partners?"
Answer="In some situations we will receive a payment from our partners when you take out a policy through one of the links in the Money Guided app. However, we do not keep this payment - instead we pass this to you as a Money Guided Savings Boost."/>

<div class="row">
	<h2>Money Guided Savings Boost</h2>
</div>

<question-and-answer Question="What is the Money Guided Savings Boost?"
Answer="The Money Guided Savings Boost is our way of demonstrating that our interests are aligned to yours. When you take out a policy through one of our partners any payment we receive as commission from the partner will be passed to you. We've called it a Savings Boost as we think this is typically the best use of the money. Having more savings supporting you is a great way to feel better about your financial situation."/>

<question-and-answer Question="How much will I get?"
Answer="The actual amount you receive will depend on the specifics of the policy you take out. We do not advertise the amount size of the Savings Boost beforehand as we do not want our members to make any financial decisions which are influenced by the Savings Boost."/>

<question-and-answer Question="When will I get my Savings Boost?"
Answer="In most cases, you will receive the savings boost within 90 days of taking out the policy. If you have not received a Savings Boost that you would have expected to receive within this time, please email help@moneyguided.com, or select Help from the Settings menu in the app."/>

<div class="row">
	<h2>Safety and Security</h2>
</div>

<question-and-answer Question="Is the Money Guided app safe and secure?"
Answer="Yes, we take the security of your data very seriously. Money Guided will protect it using biometric security and bank-level 256-bit encryption. Accounts will be securely connected through Open Banking APIs, giving you complete control at all times. Your privacy is important to us. Money Guided takes serious precautionary measures to ensure that your personal details are held in absolute confidence. Your personal details and your account data will always be encrypted and stored on secure servers. Read our privacy policy here."/>

<question-and-answer Question="Is Money Guided a regulated financial institution?"
Answer="Yes, Money Guided acts as an agent of TrueLayer, who is providing the regulated Account Information Service, and is Authorised and Regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 and the Electronic Money Regulations 2011 (Firm Reference Number: 903037)."/>

<div class="row">
	<h2>Contacting Money Guided</h2>
</div>

<question-and-answer Question="How can I contact Money Guided?"
Answer="If you need help with anything, we would like to know so that we can help you as soon as possible. Please email help@moneyguided.com or select Help from the Settings menu in the app."/>

<question-and-answer Question="How quickly will you get back to me?"
Answer="We want to resolve any issues as soon as we can and will typically respond to your email within 6 working hours."/>


	</div>
</template>

<script>
import QuestionAndAnswer from './QuestionAndAnswer.vue'
export default {
  components: { QuestionAndAnswer },
}
</script>

<style scoped>
	#faq {
		padding-top: 130px;
		padding-bottom: 130px;
		width: 47%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	h1 {
		font-size: 27px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	h2 {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 5px;
		margin-top: 30px;
	}
	.row {
		text-align: left;
	}
	@media all and (max-width: 767px)
	{
		#faq {
			p--adding: 50px;
		}
	}
</style>