<template>
  
    <div :class="'row showcase showcase-right showcase-' + Variant" :style="{'min-height' : height + 'px'}">
      
      <div v-if="mobile" class="col-md-7" :class="First ? 'first' : ''">
        <a v-if="ImageLink" :href="ImageLink" target="_blank">
          <img class="fluid" :src="Image" />
        </a>
        <img v-if="!ImageLink" class="fluid" :src="Image" />
      </div>

      <div class="col-md-5">
        <h1 v-html="Heading"></h1>
        <p v-html="Body"></p>
        <p class="sources" v-if="Sources" v-html="Sources"></p>
        <br/>
        <br/>
        <router-link v-if="Link" :to="'/' + Link"><b-button pill class="float-left btn-mg btn-mg-arrow">Learn more</b-button></router-link>
        <b-button v-if="!Link && Contact" pill v-b-modal.contact-modal class="float-left btn-mg btn-mg-arrow">Contact Us</b-button>
      </div>
      
      <div v-if="!mobile" class="col-md-7" :style="{'background-color' : (AltColors ? 'red': '')}">
        <a v-if="ImageLink" :href="'#/' + ImageLink" target="_blank">
          <img class="fluid" :src="Image" />
        </a>
        <img v-if="!ImageLink" class="fluid" :src="Image" />
      </div>
      
      <img v-if="First" class="down" src="@/assets/down.svg" />
    </div>
  
</template>

<script>
export default {
  props: ["Heading", "Body", "Image", "Variant", "AltColors", "Link", "ImageLink", "First", "Contact", "Sources"],
  data() {
    return {
      height : 0,
      width: 0,
      mobile : false
    }
  },
  methods: {
    scale : function(){
      this.height = window.innerHeight - (this.First ? 0 : 60);
      this.width = window.innerWidth;
      this.mobile = this.width < 767;
    },
  },
  created: function(){ 
    this.scale();
    window.addEventListener("resize", this.scale);
  },
  destroyed() {
    window.removeEventListener("resize", this.scale);
  }
};
</script>

<style scoped>
.showcase {
  background-size: cover;
  background-repeat: no-repeat;
}
.showcase .col-md-5 {
  padding-left: 130px;
  color: #271c3a;
  margin-top: auto;
  margin-bottom: auto;
}
.showcase .col-md-7 {
  padding-left: 10%;
  padding-right: 10%;
  color: #271c3a;
  vertical-align: middle;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
.showcase h1 {
  font-size: 28px;
  margin-bottom: 2px;
  font-weight: 700;
}
.showcase h3 {
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}
.showcase img {
  max-height:700px;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.showcase-right h1,
.showcase-right h2 {
  text-align: left;
}
.showcase-dark {
  background-color: #faf9f7;
}
.showcase p {
  text-align: left !important;
  font-size: 12px;
  margin-top: 10px;
  z-index: 1;
  max-width: 400px;
  line-height: 2;
}
.showcase .btn {
  width: 120px;
}
h2 {
  font-size: 20px;
}
.down {
  position: absolute;
  bottom: 20px;
  display: block;
  height: 20px;
  left: 50%;
  margin-left: -10px;
}
.sources {
  font-size: 9px;
}
.plain-link {
  text-decoration: none !important;
  color: #271c3a !important;
}
@media all and (max-width: 767px)
{
  .first {
    padding-top: 70px !important;
  }
  .showcase, .showcase h1, .showcase h2{
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    max-width: unset;
  }
  .showcase {
    margin-left: 0px;
    margin-right: 0px;
  }
  .showcase p {
    text-align: center !important;
    margin-left: 40px;
    margin-right: 40px;
    max-width: unset;
  }
  .showcase .col-md-5 {
    padding-left:0px;
    text-align: center;
    padding-top: 50px;
  }
  .showcase .col-md-7 {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
  .showcase .btn {
    float: unset !important;
    margin-bottom: 40px;
  }
  .showcase img {
    padding-top: 20px;
  }
}
</style>
