<template>
	<div id="welcome">
		<iframe
			:src="srcUri" 
			frameborder="0" 
			allowfullscreen 
			:style="{'min-height' : height + 'px'}"
			>
		</iframe>
	</div>
</template>

<script>

export default {
	components: {  },
	data() {
		return {
			height : 0,
			width: 0,
			mobile : false,
			srcUri : ""
		}
	},
	methods: {
		scale : function(){
			this.height = window.innerHeight;
			this.width = window.innerWidth;
			this.mobile = this.width < 767;
			this.srcUri = (this.mobile ? 'https://xd.adobe.com/embed/04ed4518-bc3d-48e4-ae4f-3f739e0e4e17-51be/' : 'https://xd.adobe.com/embed/369f406c-8b38-4bbb-a0c2-93008742902e-63cc/')
		},
	},
	created: function(){ 
		this.scale();
		window.addEventListener("resize", this.scale);
	},
	destroyed() {
		window.removeEventListener("resize", this.scale);
	}
}
</script>

<style scoped>
	iframe {
		margin-top: 0px;
		border: 0px;
		height: 900px;
		width: 100%;
	}
	h1 {
		margin-top: 50px;
		text-align: left;
		margin-bottom: 30px;
		font-size: 25px;
		font-weight: bold;
	}
	h2 {
		font-size: 20px;
		margin-bottom: 30px;
		text-align: left;
	}
	p {
		text-align: left;
	}
	.menu, .bottom-menu {
		display: none !important;
	}
</style>
