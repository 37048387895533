<template>
	<div id="pdf">
		<iframe width="100%" height="900px" frameborder="0" src="Terms.pdf">
		</iframe>
	</div>
</template>

<script>
export default {
	components: {  }
}
</script>

<style scoped>
	#pdf {
		padding-top: 60px;
	}
</style>