<template>
  <div class="content">
    <showcase
      Variant="light"
      Heading="Your financial life<br/>
        at your fingertips"
      Body="Money Guided helps you better understand your money, make plans and achieve your financial goals."
      :Image="require('@/assets/F1.png')"
      First="true"
    />

    <showcase
      Variant="dark"
      Heading="All in one place"
      Body="Money Guided brings your financial life together, connecting your accounts and financial policies into one secure and easy to-manage place."
      :Image="require('@/assets/F2.png')"
    />

    <video-showcase
      Heading="Unlock the<br/>
power of Moves"
      Body="With your financial life connected, our powerful
        Moves engine delivers insight, guidance and
        access to the right products and services at the
        right time."
      :Video="require('@/assets/AtmPinPad.mp4')"
      :MobileVideo="require('@/assets/AtmPinPad_Mobile.mp4')"
    />

    <showcase
      Variant="dark"
      Heading="Make Moves.<br/>
        Feel better."
      Body="Want more control of your day-to-day spending?<br/>
        Want to build your financial resilience?<br/>
        Want to better understand your financial habits?<br/><br/>
        There's a Move for that."
      :Image="require('@/assets/F4.png')"
    />

    <video-showcase
      Heading="Get more with a<br/>
        Money Guided<br/>
        Savings Boost"
      Body="To help you find the right products for you, we have
        partnerships with a wide range of insurance and
        finance specialists. And if you buy through them,
        should we receive any commission, it's all yours!"
      :Video="require('@/assets/CheeseWire.mp4')"
      :MobileVideo="require('@/assets/CheeseWire_Mobile.mp4')"
    />

    <showcase
      Variant="light"
      Heading="Never miss a<br/>
        renewal date or a<br/>
        great deal again"
      Body="Switching providers to a better deal is
        usually pretty quick. Remembering to do so
        is often the challenge. Through our trusted
        partners Money Guided will help you find
        the right deal, at the right time."
      :Image="require('@/assets/F6.png')"
    />

    <video-showcase
      Heading="See your spending in<br/>
        a whole new way"
      Body="Spending Analysis provides the insight into your
        spending, while Spending Plan helps you set
        monthly saving targets. Working together to help
        you take control and plan out your spending."
      :Video="require('@/assets/MarblePuzzle.mp4')"
      :MobileVideo="require('@/assets/MarblePuzzle_Mobile.mp4')"
    />

    <showcase
      Variant="light"
      Heading="More connected<br/>
        with your money"
      Body="By linking your current accounts and credit
        cards, Spending Analysis brings all your
        transactions into view by grouping them by
        category. Providing complete visibility over
        your money."
      :Image="require('@/assets/F7.png')"
    />

    <showcase
      Variant="dark"
      Heading="Creating a<br/>
        Spending Plan<br/>
        is easy"
      Body="Once you've identified areas of your
        spending you'd like to work on, now it's time
        to create a plan. Select a category, set the
        amount and stay on target through the
        month."
      :Image="require('@/assets/F8.png')"
    />

    <video-showcase
      Heading="Small steps made<br/>
      regularly can lead<br/>
      to big changes"
      Body="Developed by our team of finance experts and
        clinical psychologists Money Guided provides
        the insights and tools to help you take action,
        make plans and achieve your goals. Our
        intention is to help you develop a more positive
        relationship with your finances."
      :Video="require('@/assets/Polos.mp4')"
      :MobileVideo="require('@/assets/Polos_Mobile.mp4')"
    />

    <showcase
      Variant="light"
      Heading="Become better<br/>
        prepared"
      Body="Money Guided provides the plan to improve
        your Financial Resilience. This is your ability
        to withstand unexpected events or any other
        significant changes in your life."
      :Image="require('@/assets/F9.png')"
    />

    <showcase
      Variant="dark"
      Heading="Grow your knowledge<br/>
        &amp; money confidence"
      Body="Written by our team of financial experts and
        psychologists, the Guide delivers articles with your
        financial situation, plans and goals in mind."
      :Image="require('@/assets/F10.png')"
    />

    <showcase
      Variant="light"
      Heading="Acknowledge how<br/>
        far you've come"
      Body="The Reflections area provides a summary of
        articles read, the Achievements earned and
        the Moves made, helping celebrate the
        positive actions you’ve taken."
      :Image="require('@/assets/F11.png')"
    />


  </div>

</template>

<script>
import Showcase from "./Showcase.vue";
import VideoShowcase from "./VideoShowcase.vue";

export default {
  components: { Showcase, VideoShowcase },
  name: "Home",
};
</script>

<style scoped>
</style>
