<template>
  <div class="content">
    <title-hero
      Heading="Money Guided"
      SubHeading="Feel Good Financial Outcomes"
      :Video="require('@/assets/PeeledOrange.mp4')"
      :MobileVideo="require('@/assets/PeeledOrange_Mobile.mp4')"
      :Still="require('@/assets/HeroBG.png')"
      :MobileStill="require('@/assets/HeroBG_Mobile.png')"
    />

    <showcase
      Variant="light"
      Heading="Welcome to<br />Money Guided"
      Body="Money Guided is a financial management app that helps you better understand and take control of your finances.<br/><br/>
        Our intention is to help you develop a more positive relationship with your money.<br/><br/>
        The employer-paid subscription is a revolutionary way for organisations to support and empower their teams financially."
      Link="features"
      :Image="require('@/assets/ThreePhones2023.png')"
    />

    <showcase
      Variant="dark"
      Heading="All in one place"
      Body="Money Guided brings your financial life together, connecting your accounts and financial policies into one secure and easy to-manage place."
      :Image="require('@/assets/HomePanels.png')"
    />

    <showcase
      Variant="light"
      Heading="Unlock the<br/>power of Moves"
      Body="With your financial life connected, our powerful Moves engine delivers insight, guidance and access to the right products and services at the right time."
      :Image="require('@/assets/Moves.png')"
    />

    <showcase
      Variant="dark"
      Heading="Make Moves.<br/>Feel better."
      Body="Want more control of your day-to-day spending?<br/>
        Want to build your financial resilience?<br/>
        Want to better understand your financial habits?<br/><br/>
        There's a Move for that."
      Link="features"
      :Image="require('@/assets/3Moves.png')"
    />

    <video-showcase
      Heading="Save money with<br/>
      a Savings Boost"
      Body="To help you find the right products for you, we have
        partnerships with a wide range of insurance and
        finance specialists. And if you buy through them,
        should we receive any commission, it's all yours!"
      :Video="require('@/assets/CheeseWire.mp4')"
      :MobileVideo="require('@/assets/CheeseWire_Mobile.mp4')"
    />

    <feature-carousel Heading="More from Money Guided" />

    <video-showcase
      Heading="We keep your<br/>
        information safe"
      Body="At Money Guided, we take the security of your data and
        information very seriously. We protect it using biometric
        security and bank-level 256-bit encryption. Accounts are
        connected through Open Banking APIs, giving you complete
        control at all times.
      <br/><br/>
      • Biometric security<br/>
      • Bank-level, 256-bit encryption"
      :Video="require('@/assets/Safe.mp4')"
      :MobileVideo="require('@/assets/Safe_Mobile.mp4')"
    />

    
  </div>
</template>

<script>
//import Feature from "./Feature.vue";
//import FeatureShowcase from "./FeatureShowcase.vue";
import FeatureCarousel from "./FeatureCarousel.vue";
import Showcase from "./Showcase.vue";
import TitleHero from "./TitleHero.vue";
import VideoShowcase from "./VideoShowcase.vue";
export default {
  components: { TitleHero, Showcase, VideoShowcase, /*FeatureShowcase, Feature,*/ FeatureCarousel },
  name: "Home",
};
</script>

<style scoped>

</style>
