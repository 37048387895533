import Vue from 'vue';
import App from './App.vue';
import Home from './components/Home.vue';
import Employers from './components/Employers.vue';
import Features from './components/Features.vue';
import FAQ from './components/FAQ.vue';
import Articles from './components/Articles.vue';
import Article from './components/Article.vue';
import Download from './components/Download.vue';
import About from './components/About.vue';
import Policy from './components/Policy.vue';
import Terms from './components/Terms.vue';
import AppTerms from './components/AppTerms.vue';
import Linked from './components/Linked.vue';
import Welcome from './components/Welcome.vue';
import Launch from './components/Launch.vue';
import Router from'vue-router';
import axios from'axios';
import VueGtag from "vue-gtag";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Router);
Vue.use(axios);
Vue.use(VueGtag, {config: { id: "G-MV7QYPVWEZ" }});
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const router = new Router({
	routes:[
		{ path:'/', name:'home', component:Home },
		{ path:'/faq', name:'faq', component:FAQ },
		{ path:'/guide', name:'guide', component:Articles },
		{ path:'/dl', name:'guide', component:Download },
		{ path:'/employers', name:'employers', component:Employers },
		{ path:'/features', name:'features', component:Features },
		{ path:'/about', name:'about', component:About },
		{ path:'/policy', name:'policy', component:Policy },
		{ path:'/terms', name:'terms', component:Terms },
		{ path:'/appterms', name:'appterms', component:AppTerms },
		{ path:'/linked', name:'linked', component:Linked },
		{ path:'/welcome', name:'welcome', component:Welcome },
		{ path:'/launch', name:'launch', component:Launch },
		{ path:'/article/:id', name:'article', component:Article }
	],
	scrollBehavior() {
        return {x: 0, y: 0}
    }
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
