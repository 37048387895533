<template>

  <div class="feature-carousel">
    <div class="row">

      <h1>{{Heading}}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <carousel-3d :count="5" :width="459" :height="489" :border="0" :disable3d="true" :display="4" :loop="true" :space="465" :controls-visible="true" >
          <a href="/#/features">
            <slide :index="0">
              <img src="@/assets/C1.png">
            </slide>
          </a>
          <a href="/#/features">
            <slide :index="1">
              <img src="@/assets/C2.png">
            </slide>
          </a>
          <a href="/#/features">
            <slide :index="2">
              <img src="@/assets/C3.png">
            </slide>
          </a>
          <a href="/#/features">
            <slide :index="3">
              <img src="@/assets/C4.png">
            </slide>
          </a>
          <a href="/#/features">
            <slide :index="4">
              <img src="@/assets/C5.png">
            </slide>
          </a>
        </carousel-3d>
      </div>
    </div>
  </div>

</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide
  },
  props: ["Heading"]
};
</script>

<style scoped>
  .feature-carousel h1 {
    text-align: left;
    margin-left: 140px;
    margin-top: 60px;
    font-size: 28px;
    margin-bottom: 25px;
    font-weight: 700;
  }
  .feature-carousel {
    background-color: #faf9f7;
    padding-bottom: 30px;
  }
  .carousel-3d-slide {
    background-color: unset !important;
  } 
  @media all and (max-width: 767px)
  {
    .feature-carousel h1{
      text-align: center;
      margin-left: 10px;
      margin-right: 15px;
      margin-left: 15px;
      max-width: unset;
      width: 100%
    }
    h1 {
      margin-right: 35px !important;
      margin-left: 35px !important;
    }
  }
</style>
