<template>
	
	<div class="bottom-menu">
		<div class="row menu">
			
			<div class="col-md-1"></div>
			<div class="col-md-1">
				<router-link class="w-link no-router-link-active" to="/">
					<img src="@/assets/MGLogo.png" height="25px">
				</router-link>
			</div>
			<div class="col-md-1"></div>

			<div class="col-md-2 left">
				<router-link class="w-link" to="/employers">Employers</router-link><br/>
				<router-link class="w-link" to="/features">Features</router-link><br/>
				<router-link class="w-link" to="/about">About</router-link><br/>
				<router-link class="w-link" to="/guide">Guide</router-link>
			</div>

			<div class="col-md-4">
				<router-link class="w-link" to="/faq">FAQ's</router-link><br/>
				<a class="w-link" href="#" v-b-modal.contact-modal>Contact Us</a><br/>
				<router-link class="w-link" to="/policy">Privacy Policy</router-link><br/>
				<router-link class="w-link" to="/terms">Terms of Use</router-link> -->
			</div>

		</div>
		<div class="row">
			<div class="col-md-3"></div>
			<div class="col-md-6">
				<hr/>
			</div>
		</div>
		<div class="row blurb">
			<div class="col-md-3"></div>
			<div class="col-md-6 disclaimer">
				<p>SYSO Limited trading as Money Guided is acting as an agent of TrueLayer, who is providing the regulated 
				Account Information Service, and is Authorised and Regulated by the Financial Conduct Authority under the 
				Payment Services Regulations 2017 and the Electronic Money Regulations 2011 (Firm Reference Number: 903037)</p>
				<p>Money Guided ®</p>
			</div>
			
		</div>
	</div>

</template>

<script>
export default {
 props: {
   menuMode: String
 },
 methods: {
   menuEvent(mode) {
		this.menuMode = mode;
		this.$emit('menuEvent', mode);
   }
 }
}
</script>

<style scoped>
	hr{
		background-color: #aaaaaa4b;
	}
	.no-router-link-active {
		border-bottom: none !important;
	}
	.r-outer-link-active {
		border-bottom: 2px solid #F7DFD2;
	}
	.row {

		background-color: #271c3a !important;
	}
	.menu {
		padding: 40px;
		text-align: left;
	}
	.menu img {
		margin-right: 10px;
	}
	.w-link, .w-link:hover, .w-link:active {
		color: white;
		text-decoration: none;
		margin-right: 40px;
		font-size: 13px;
	}
	.blurb p {
		color: white;
		font-size: 10px !important;
		margin-right: auto;
		margin-left: auto;
		text-align: justify;
		color: #aaaaaa;
	}
	.blurb{
		padding-top: 30px;
		padding-bottom: 40px;
	}
	.disclaimer {
		font-style: italic;
	}
	@media all and (max-width: 991px)
	{
		.blurb{
			padding-bottom: 40px;
			padding: 40px;
			padding-top: 20px;
		}
		hr {
			width: 80%;
		}
		.menu {
			padding-bottom: 20px;
		}
		img {
			padding-bottom: 7px;
		}
	}
</style>