<template>
	<a :href="Link">
		<div class="card">
			<img class="card-img-top" role="img" v-if="Image != null && Image != ''" :src="Image" />
			<div class="card-body">
				<h3>{{Tag}}</h3>
				<h2>{{Title}}</h2>
				<p>{{ShortSummary}}</p>
			</div>
		</div>
	</a>
</template>

<script>

export default {
	props: ["Link", "File", "Title", "Image", "ShortSummary", "Tag", "ID"],
}
</script>

<style scoped>
	a , a:active, a:hover{
		color: #271c3a;
		text-decoration: none;
	}
	.card {
		border: 0px;
		margin-top: 50px;
	}
	.card h2 {
		font-size: 20px;
		font-weight: bold;
	}
	.card h3 {
		font-size: 14px;
		font-weight: bold;
	}
	.card p {
		font-size: 14px;
	}
	.card-body{
		b--order-bottom: 3px solid #271c3a;
	}
</style>