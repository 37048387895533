<template>
	<div class="article" v-if="article">
		<div class="row">
			<div cass="col-md-12"><router-link :to="'/guide'"><img class="nav" src="@/assets/down.svg" /></router-link></div>
		</div>
		<div class="row">
			<div cass="col-md-12"><img class="fluid" :src="article.image" /></div>
		</div>
		<div class="row">
			<div cass="col-md-12"><h3>{{article.tagsArray[0]}}</h3></div>
		</div>
		<div class="row">
			<div cass="col-md-12"><h1>{{article.title}}</h1></div>
		</div>
		<div class="row">
			<div cass="col-md-12"><h2>{{article.shortSummary}}</h2></div>
		</div>
		<div class="row">
			<div cass="col-md-12"><p v-html="article.body.replaceAll('\n', '<br/>').replaceAll('<h3>', '<b>').replaceAll('</h3>', '</b>')" /></div>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			article: null
		};
	},
	methods: {
		loadArticle: function(id) {
			this.$http.get("https://api.sysodev.link/CMS/Articles?forApp=false&forWeb=true")
			.then(
				response => {
					for (var x = 0; x < response.data.length; x++)
					{
						if (response.data[x].id == id) {
							this.article = response.data[x];
						}
					}
				}
			);
		}
	},
	created: function() {
		this.loadArticle(this.$route.params.id);
	}
}

</script>

<style scoped>
	.article {
		max-width: 600px;
		margin-left:auto;
		margin-right: auto;
		margin-bottom:100px;
		margin-top: 100px;
		width: 70%;
	}
	.article .nav {
		transform: rotate(90deg);
		margin-bottom: 10px;
		width: 15px;
	}
	.article img {
		max-width: 100%;
	}
	h1 {
		margin-top: 0px;
		text-align: left;
		margin-bottom: 5px;
		font-size: 25px;
		font-weight: 700;
	}
	h2 {
		font-size: 17px !important;
		margin-top: 10px;
		margin-bottom: 20px;
		text-align: left;
		font-weight: bold;
	}
	h3, p h3, .h3 {
		font-size: 15px !important;
		margin-top: 50px;
		margin-bottom: 5px;
		text-align: left;
		font-weight: bold;
	}
	p{
		text-align: justify;
	}
</style>